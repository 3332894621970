import React, { useState, useReducer, useEffect, useRef, useCallback } from "react";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasicModal from "./BasicModal";
import { useDispatch } from "react-redux";
import { deletePostAction, getPostsAction, updatePostAction } from "../../../store/actions/PostActions";
import Kendaraan from "../../../images/kendaraan.png";
import logo from "../../../images/logo2.png";
import logo3 from "../../../images/logo3.png";
import moment from "moment";
import Swal from "sweetalert2";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
export const Activities = ({ history = false }) => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const childRef = useRef();
  const [printItem, setPrintItem] = useState('');
  const [code, setCode] = useState('');
  const [inputPlat, setInputPlat] = useState('');
  const itemsPerPage = 10;
  const totalPages = Math.ceil(activities.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentActivities = activities.slice(startIndex, startIndex + itemsPerPage);
  const [status, setStatus] = useState(0);
  const [startAt, setStartAt] = useState();
  const [endAt, setEndAt] = useState();
  const [tarifList, setTarifList] = useState([]);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    fetchActivities()
  }, [currentPage, status])
  const checkTarif = (time) => {
    const now = new Date();
    const inputTime = new Date(time);
    const timeDifference = now - inputTime;

    const fifteenMinutes = 15 * 60 * 1000;

    return timeDifference > 0 && timeDifference <= fifteenMinutes;
  };
  const destroyActivity = async (e, item) => {
    e.preventDefault();
    let tarif = item.tarif;
    if (checkTarif(item.createdAt)) {
      tarif = 0;
    }
    const result = await Swal.fire({
      title: "Keluarkan Kendaraan",
      html: `
    <div class="form-group text-start fw-bold">
    Kode Tiket/Plat
    <input class="form-control mt-2 bg-gray" disabled value="${item.plat}"/>
    <div/>
    <div class="form-group mt-2 text-start fw-bold">
    Tarif
    <input class="form-control mt-2 bg-gray" disabled value="${tarif ? 'Rp.' + tarif : 'Gratis Parkir'}"/>
    <div/>
    <div class="form-group text-start fw-bold mt-3">
    Kendaraan Masuk
    <input class="form-control mt-2 bg-gray" disabled value="${moment(item.time).format('DD MMMM hh:mm')}"/>
    <div/>
  `,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Keluarkan"
    });
    if (result.isConfirmed) {
      const response = await dispatch(updatePostAction('/kendaraan/update', { keluar: true, tarif: tarif }, item.id))
      if (response) {
        fetchActivities()
      }
    }

  }
  const fetchActivities = async (plat = null) => {
    try {
      const posts = await dispatch(getPostsAction('/kendaraan/index', {
        params: {
          plat: plat,
          keluar: status,
          startAt: startAt,
          endAt: endAt,
          pageSize: 10,
          pageNumber: currentPage,
        },
      }))
      if (posts) {
        setActivities(posts)
      }
    } catch (error) {
    }
  }
  const fetchTarif = async () => {
    try {
      const posts = await dispatch(getPostsAction('/tarif/index', {
      }))
      if (posts) {
        setTarifList(posts.reduce((acc, item) => {
          acc[item.type] = item.tarif;
          return acc;
        }, {}))
      }
    } catch (error) {
    }
  }
  const printAreaRef = useRef(null);
  const onBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  }, [printItem]);
  const handlePrint = useReactToPrint({

    content: () => printAreaRef.current,
    onBeforeGetContent,
  });
  const onPrintClick = (item) => {
    setPrintItem(item);
    handlePrint();
  };
  let timeoutId
  const loadUser = (inputValue) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if (inputValue.trim()) {
        fetchActivities(inputValue);
      }
      if (!inputValue) {
        fetchActivities()
      }
    }, 500);
  };
  useEffect(() => {
    fetchTarif();
  }, [])
  useEffect(() => {
    fetchActivities();
  }, [startAt, endAt])
  return (
    <>
      <div className="d-flex ">

        {!history &&
          <div className="d-flex gap-3 ">
            <img src={Kendaraan} />
            <div>
              <h3>Data Kendaraan</h3>
              <span className="fs-5">{activities.length} Kendaraan</span>
            </div>
          </div>
        }

        <div className="d-flex justify-content-end gap-3 mb-3 flex-grow-1" >

          {history &&
            <>
              <div className="align-items-end d-flex gap-3 me-3">
                <button className={`btn ${status == 0 ? 'btn-secondary' : 'btn-outlined-secondary'} d-flex w-auto align-self-start py-1`} onClick={() => setStatus(0)}>Masuk</button>
                <button className={`btn ${status == 1 ? 'btn-secondary' : 'btn-outline-secondary'} d-flex w-auto align-self-start py-1`} onClick={() => setStatus(1)}>Keluar</button>

              </div>
              <div className="align-items-end d-flex gap-3 me-3">
                <div className="form-group">
                  <label htmlFor="mulai">Filter Dari</label>
                  <input id="mulai" className="form-control" type="datetime-local" onChange={(e) => setStartAt(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="hingga">Hingga</label>
                  <input id="hingga" className="form-control" type="datetime-local" onChange={(e) => setEndAt(e.target.value)} />
                </div>
              </div>
            </>
          }
          <div className="form-group">
            <label></label>
            <div className="input-group search-area ">
              <input
                type="text"
                className={`form-control active`}
                placeholder="Cari plat kendaraan"
                onChange={(e) => loadUser(e.target.value)}
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                      fill="#01A3FF"
                    />
                  </svg>
                </Link>
              </span>
            </div>
          </div>
          <div className="py-3">
            {
              !history &&
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => childRef.current.openModal()}
              >
                + Tambah Kendaraan
              </button>

            }
          </div>
        </div>

      </div>
      <div ref={printAreaRef} className="print-area">
        <div className="ticket-container">
          <div className="placeholder-circle">
            <img src={logo3} height={50} className="logo-dark d-inline m-0" alt="logo" />
          </div>
          <h2 className="ticket-header">Karcis Parkir</h2>
          <h4 className="hospital-name">RSUD Dr. H. Yuliddin Away</h4>
          <hr className="m-0"/>
          <div className="form-group">
            <label htmlFor="licensePlate" className="license-plate-label fw-bold">Nomor Polisi</label>
            <div id="licensePlate">
              <Barcode value={printItem.plat} width={1.2} height={40} fontSize={8} margin={1} format="CODE128" />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="entryTime" className="entry-time-label fw-bold">Jam Masuk</label>
            <div id="entryTime" className="entry-time fw-bold">
              {moment(printItem.createdAt).format('DD MMMM hh:mm')}
            </div>
          </div>

          <p className="fine-text fw-bold">
            Kehilangan karcis parkir akan didenda sebesar Rp 10.000
          </p>

          <p className="fine-text fw-bold">
            Create by <img src={logo} className="logo-dark d-inline" alt="logo" height="20" />
            <a href="https://markirin.com" target="_blank"> markirin.com</a>
          </p>
        </div>

      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Jenis Kendaraan</th>
            <th>Plat</th>
            <th>Waktu</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((item, key) => (
            <tr key={key}>
              <th>{key + 1}</th>
              <td className="align-top">
                {item.type}
              </td>
              <td className="align-top">
                {item.plat}
              </td>
              <td className="align-top">
                {moment(item.createdAt).format('DD MMMM hh:mm')}
              </td>
              <td className="align-top">
                <div className="d-flex justify-content-center gap-2">
                  {/* {
                    !status && <button title="Edit" className="btn btn-primary shadow btn sharp" onClick={() => childRef.current.openEdit(item)}>
                      <i className="fas fa-pencil-alt color-muted"></i>
                    </button>
                  } */}

                  <button title="Edit" className="btn btn-secondary shadow btn sharp" onClick={() => onPrintClick(item)}>
                    <i class="fa-solid fa-print"></i>
                  </button>
                  {
                    !status &&
                    <button
                      onClick={(e) => destroyActivity(e, item)}
                      title="delete"
                      className="btn btn-danger shadow btn py-1"
                    >
                      Keluarkan
                    </button>
                  }
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="table-pagenation">
        <p className="ms-0">
          Showing <span>{startIndex + 1}-{Math.min(startIndex + currentActivities.length, activities.length)}</span>
          from <span>{activities.length}</span> data
        </p>

        <nav>
          <ul className="pagination pagination-gutter pagination-primary no-bg">
            <li className={`page-item page-indicator ${currentPage === 1 && "disabled"}`}>
              <Link
                to={"#"}
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i className="fa-solid fa-angle-left"></i>
              </Link>
            </li>

            {/* Generate pagination numbers */}
            {[...Array(totalPages)].map((_, index) => (
              <li className={`page-item ${currentPage === index + 1 ? "active" : ""}`} key={index}>
                <Link
                  to={"#"}
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Link>
              </li>
            ))}

            <li className={`page-item page-indicator me-0 ${currentPage === totalPages && "disabled"}`}>
              <Link
                to={"#"}
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <i className="fa-solid fa-angle-right"></i>
              </Link>
            </li>
          </ul>
        </nav>

      </div>
      <BasicModal fetchActivities={fetchActivities} tarifList={tarifList} ref={childRef} onPrintClick={onPrintClick} />
    </>
  );
};
export default Activities;
